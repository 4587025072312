/**
 * AmeriHealth Caritas colors schema
 */

$app-color-primary: #ee7623;
$app-color-secondary: #009877;
$app-color-primary-dark: #00609a;

$app-color-tone: #006fd2;
$app-color-primary-light-1: #6fa6e5;
$app-color-primary-light-3: #eeeeee;

$app-color-white: #fff;
$app-color-black: #1f2020;
$app-color-gray-dark: #77706f;

$app-color-warning: #ffc107;
$app-color-danger: #dc3545;

$app-background-gradient: linear-gradient(90deg, #cfdfe8 0 , #cfdfe8 100%);