// .form-group .label
$form-group-label-font-weight: normal;
$form-group-label-text-transform: capitalize;
$form-group-label-color: #444;
$form-group-label-line-height: 16px;
$form-group-label-font-size: 12px;
$form-group-label-margins: (
  "bottom": 0
);

// .form-group.label-large .label
$form-group-label-large-label-font-size: 16px;

// .form-group .form-control
$form-control-placeholder-font-style: normal;
$form-control-border-color: #cacaca;
$form-control-border-radius: 4px;
$form-control-height: 40px;
$form-control-padding: 9px;
$form-control-font-size: 14px;
$form-control-line-height: 22px;
$form-control-background: #fff;
$form-control-disabled-background: #eeeeee;

// .form-group .form-control::placeholder
$form-control-placeholder-font-style: normal;
$form-control-placeholder-text-transform: lowercase;
